import React from 'react'

class GoogleAddressInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addressValue: '',
    }
    this.autocompleteInput = React.createRef()
    this.autocomplete = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount() {
    this.autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteInput.current, {
      types: ['address'],
    })

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
  }
  handlePlaceChanged() {
    const place = this.autocomplete.getPlace()
    const address = this.extractAddress(place)
    this.props.onAddressChanged(address)
  }

  extractAddress(place) {
    const { formatted_address, address_components, place_id } = place
    let street_number,
      route,
      locality,
      administrative_area_level_1,
      administrative_area_level_2,
      country,
      postal_code,
      latitude,
      longitude
    street_number = this.getAddrPropByKey(address_components, 'street_number')
    route = this.getAddrPropByKey(address_components, 'route')
    locality = this.getAddrPropByKey(address_components, 'locality')
    administrative_area_level_1 = this.getAddrPropByKey(
      address_components,
      'administrative_area_level_1',
    )
    administrative_area_level_2 = this.getAddrPropByKey(
      address_components,
      'administrative_area_level_2',
    )
    country = this.getAddrPropByKey(address_components, 'country')
    postal_code = this.getAddrPropByKey(address_components, 'postal_code')
    latitude = place.geometry.location.lat()
    longitude = place.geometry.location.lng()
    this.setState({ addressValue: formatted_address })
    return {
      street_number,
      route,
      locality,
      administrative_area_level_1,
      administrative_area_level_2,
      country,
      postal_code,
      formatted_address,
      google_place_id: place_id,
      latitude,
      longitude,
    }
  }

  getAddrPropByKey(address_components: any, key: string) {
    let data = address_components.filter((elem: any) => {
      return elem['types'][0] === key
    })
    if (data[0]) return data[0]['short_name']
    return ''
  }

  render() {
    const { className, name, id, placeHolder } = this.props
    return (
      <input
        ref={this.autocompleteInput}
        className={className}
        name={name}
        id={id}
        placeholder={placeHolder}
        type="text"
        value={this.state.addressValue || this.props.defaultValue}
        onChange={(e) => this.setState({ addressValue: e.target.value })}
        autoComplete="off"
      />
    )
  }
}

export default GoogleAddressInput
