import { get } from '../utils/request'
import apiRoot from '../_data/apiRoot'

class SalesProduct {
  async getSalesProductReport() {
    var now = new Date()
    var month = now.getMonth() + 1
    var day = now.getDate()
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    var today = now.getFullYear() + '-' + month + '-' + day
    var start_date = now.getFullYear() + '-' + month + '-' + '01'
    const startDate = '2022-07-22'
    const endDate = today
    const response = await get(
      'report',
      `sales-by-product?start_date=${startDate}&end_date=${endDate}`,
      'GET',
    )
    return response
  }

  async getCalculateDate(addDays) {
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    var date = new Date()
    var calDate = date.addDays(addDays)
    var month = calDate.getMonth() + 1
    var day = calDate.getDate()
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    return calDate.getFullYear() + '-' + month + '-' + day
  }
  async getPDFURL(pdfUrl) {
    var apiRootValue = `${apiRoot['report']}`
    console.log(apiRootValue.split('api/')[0])
    var pdf_url = apiRootValue.split('api/')[0] + pdfUrl.pdfUrl
    return pdf_url
  }
}
export default SalesProduct
