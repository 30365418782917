import sha1 from 'sha1'
import owasp from 'owasp-password-strength-test'
import Cookies from 'universal-cookie'

import { createTypes, createAction, transformNetworkError } from 'utils/actions'

import { get, post } from 'utils/request'
import { makeSelectTokenId } from './selectors'
import {
  hideSpinnerAction,
  showErrorToastAction,
  showSpinnerAction,
  showSuccessToastAction,
} from '../../store/actions/global'
import { navigateToPage } from '../../utils/route'
const REGISTER = createTypes('ZOOM/AuthPage/REGISTER')
const LOGIN = createTypes('ZOOM/AuthPage/LOGIN')
const LOGOUT = 'ZOOM/AuthPage/LOGOUT'
const PASSWORD_RESET_LINK = createTypes('ZOOM/AuthPage/PASSWORD_RESET_LINK')
const VERIFY_PASSWORD_TOKEN = createTypes('ZOOM/AuthPage/VERIFY_PASSWORD_TOKEN')
const RESET_PASSWORD = createTypes('ZOOM/AuthPage/RESET_PASSWORD')
const CHANGE_PASSWORD = createTypes('ZOOM/AuthPage/CHANGE_PASSWORD')
const VALIDATE_TOKEN = createTypes('ZOOM/AuthPage/VALIDATE_TOKEN')
const VERIFY = createTypes('ZOOM/AuthPage/VERIFY')
const GET_ADV = createTypes('ZOOM/SETTINGS/GET_CURRENCY')
const GET_PROFILE = createTypes('ZOOM/SETTINGS/GET_PROFILE')
const redirectUrl = process.env.REACT_APP_REDIRECT_URL

function loginActionSuccess(resp) {
  return loginAction.success({ ...resp.user, token: resp.token })
}

function registerActionSuccess(resp) {
  return registerAction.success({ ...resp.user, token: resp.token })
}
function verifyActionSuccess(resp) {
  return verifyAction.success(resp)
}
function verifyActionFailed() {
  return verifyAction.failed()
}

const registerAction = {
  do: () => createAction(REGISTER.DO, {}),
  success: (params) => {
    return createAction(REGISTER.SUCCESS, { ...params })
  },
  failed: (error) => createAction(REGISTER.FAILED, { error }),
}

const loginAction = {
  do: () => createAction(LOGIN.DO, {}),
  success: (params) => createAction(LOGIN.SUCCESS, { ...params }),
  failed: (error) => createAction(LOGIN.FAILED, { error }),
}
const verifyAction = {
  failed: () => createAction(VERIFY.FAILED),
  success: (params) =>
    createAction(VERIFY.SUCCESS, { email_verified_at: params.email_verified_at }),
}

const logoutAction = () => createAction(LOGOUT, {})

const passwordResetLinkAction = {
  do: () => createAction(PASSWORD_RESET_LINK.DO, {}),
  success: (email) => createAction(PASSWORD_RESET_LINK.SUCCESS, { email }),
  failed: (error) => createAction(PASSWORD_RESET_LINK.FAILED, { error }),
}

const resetPasswordAction = {
  do: () => createAction(RESET_PASSWORD.DO, {}),
  success: (token) => createAction(RESET_PASSWORD.SUCCESS, { token }),
  failed: (error) => createAction(RESET_PASSWORD.FAILED, { error }),
}

const changePasswordAction = {
  do: () => createAction(CHANGE_PASSWORD.DO, {}),
  success: () => createAction(CHANGE_PASSWORD.SUCCESS),
  failed: (error) => createAction(CHANGE_PASSWORD.FAILED, { error }),
}

const getAdv = {
  do: (params) => createAction(GET_ADV.DO, params),
  success: (currency) => createAction(GET_ADV.SUCCESS, { payload: currency }),
  failed: (errors) => createAction(GET_ADV.FAILED, { payload: errors }),
}

const getProfile = {
  do: (params) => createAction(GET_PROFILE.DO, params),
  success: (currency) => createAction(GET_PROFILE.SUCCESS, { payload: currency }),
  failed: (errors) => createAction(GET_PROFILE.FAILED, { payload: errors }),
}

const checkPasswordComplexity = (password) => {
  const optionalTestsNeeded = 3

  owasp.config({
    allowPassphrases: false,
    minLength: 12,
    minOptionalTestsToPass: optionalTestsNeeded,
  })
  const result = owasp.test(password)

  if (!result.strong) {
    throw new Error(
      [
        'Password is not sufficiently strong.',
        result.requiredTestErrors.join(' '),
        result.optionalTestsPassed < optionalTestsNeeded
          ? `The password must contain ${optionalTestsNeeded} classes of characters.`
          : '',
      ].join(' '),
    )
  }
}

const register = (postData) => async (dispatch) => {
  try {
    dispatch(registerAction.do())
    const resp = await post('user', 'auth/register', postData)
    dispatch(registerActionSuccess(resp))
    dispatch(showSuccessToastAction('Successfully registered.'))
  } catch (error) {
    dispatch(showErrorToastAction(error.body))
    dispatch(registerAction.failed(error.body))
  }
}

const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch(loginAction.do())
      const postData = {
        email,
        password,
      }
      const resp = await post('user', 'auth/login', postData)
      dispatch(loginActionSuccess(resp))
      const cookies = new Cookies()
      cookies.set('token', resp.token, { path: '/', maxAge: 2592000 })
      dispatch(getAdv.success(resp.accAdvance))
      dispatch(getProfile.success(resp.accProfile))
      dispatch(showSuccessToastAction('Successfully logged in.'))
      localStorage.setItem('isValid', resp.isValid)
      if (resp.isValid) {
        navigateToPage('/dashboard')
      } else {
        navigateToPage('/settings/organization/profile')
      }
    } catch (error) {
      dispatch(showErrorToastAction(error.body))
      dispatch(loginAction.failed(error.body))
    }
  }

const logout = () => async (dispatch, getState) => {
  try {
    const token = makeSelectTokenId(getState())
    if (token) {
      await get('user', 'auth/logout', 'GET')
    }
    dispatch(logoutAction())
    localStorage.setItem('token', null)
    const cookies = new Cookies()
    cookies.remove('token', { path: '/' })
    navigateToPage(redirectUrl)
  } catch (error) {
    dispatch(showErrorToastAction('An error occurred while logging out.'))
  }
}

const passwordResetLink = (email) => async (dispatch) => {
  try {
    dispatch(passwordResetLinkAction.do())
    const postData = {
      email,
    }
    await post('user', 'auth/forgot', postData)
    dispatch(passwordResetLinkAction.success(email))
  } catch (error) {
    dispatch(passwordResetLinkAction.failed(error))
  }
}

const changePassword = (oldPassword, newPassword) => async (dispatch, getState) => {
  try {
    dispatch(changePasswordAction.do())
    const token = makeSelectTokenId(getState())
    if (token) {
      checkPasswordComplexity(newPassword)
      const postData = {
        oldPassword: btoa(sha1(oldPassword)),
        newPassword: btoa(sha1(newPassword)),
      }
      await post('user', 'auth/password', postData, { Authorization: token })
      dispatch(changePasswordAction.success())
    }
  } catch (error) {
    dispatch(changePasswordAction.failed(transformNetworkError(error)))
  }
}

const resetPassword = (token, newPassword1, newPassword2) => async (dispatch) => {
  try {
    dispatch(resetPasswordAction.do())
    if (newPassword1 !== newPassword2 || !newPassword1) {
      throw new Error("Passwords don't match")
    }
    checkPasswordComplexity(newPassword1)
    const postData = {
      token,
      password: newPassword1,
    }
    const resp = await post('user', 'auth/forgot/confirm', postData)
    dispatch(resetPasswordAction.success(token))
    dispatch(loginActionSuccess(resp))
    navigateToPage('/')
  } catch (error) {
    dispatch(resetPasswordAction.failed(transformNetworkError(error)))
  }
}

const verifyEmail = (code, email) => async (dispatch) => {
  dispatch(showSpinnerAction())
  try {
    const postData = { code, email }
    const resp = await post('user', 'auth/verify-email', postData)
    dispatch(verifyActionSuccess(resp))
    dispatch(hideSpinnerAction())
    navigateToPage('/dashboard')
  } catch (error) {
    dispatch(verifyActionFailed())
    dispatch(showErrorToastAction(error.body))
  }
}

export {
  LOGIN,
  REGISTER,
  LOGOUT,
  PASSWORD_RESET_LINK,
  VERIFY_PASSWORD_TOKEN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  VALIDATE_TOKEN,
  VERIFY,
  GET_ADV,
  GET_PROFILE,
  checkPasswordComplexity,
  register,
  login,
  logout,
  changePassword,
  passwordResetLink,
  resetPassword,
  verifyEmail,
  loginActionSuccess,
}
