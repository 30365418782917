import { get } from '../utils/request'
import apiRoot from '../_data/apiRoot'

class OpenInvoice {
  async getOpenInvoiceReports() {
    var now = new Date()
    var month = now.getMonth() + 1
    var day = now.getDate()
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    var today = now.getFullYear() + '-' + month + '-' + day
    var start_date = now.getFullYear() + '-' + month + '-' + '01'
    const startDate = '2022-06-06'
    const endDate = today
    const response = await get('report', `open-invoice`, 'GET')
    return response
  }

  async getPDFURL(pdfUrl) {
    var apiRootValue = `${apiRoot['report']}`
    console.log(apiRootValue.split('api/')[0])
    var pdf_url = apiRootValue.split('api/')[0] + pdfUrl.pdfUrl
    return pdf_url
  }
}
export default OpenInvoice
