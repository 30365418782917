import { get } from '../utils/request'
import { inject } from 'dependency-injection-es6'
import ChartOfAccountService from './ChartOfAccountService'

class TransactionBill {
  @inject(ChartOfAccountService) chartOfAccount: ChartOfAccountService
  billDataById = null
  billPaymentDataById = null
  unpaidBillDataById = null

  async getBills() {
    const pageStart = 0
    const pageLength = 1000
    const response = await get(
      'expense',
      `bills?page=${pageStart}&page_length=${pageLength}`,
      'GET',
    )
    console.log(response[0].data)
    return response[0].data
  }

  async getBillById(id) {
    const response = await get('expense', `bills/${id}`, 'GET')
    if (response.text) {
      this.billDataById.errors = response.text
      return this.billDataById
    } else {
      this.billDataById = response.bills[0]
      this.billDataById.errors = ''
      this.billDataById.bill_list = response.bill_list
      this.billDataById.bill_tag = response.bill_tag
      return this.billDataById
    }
  }
  async getBillPaymentDataById(id) {
    const response = await get('expense', `get-payment-data/${id}`, 'GET')
    if (response.text) {
      this.billPaymentDataById.errors = response.text
      return response.text
    } else {
      this.billPaymentDataById = response
      return this.billPaymentDataById
    }
  }

  async getUnpaidBillById(id) {
    const response = await get('expense', `get-bill/${id}`, 'GET')
    if (response.text) {
      this.unpaidBillDataById.errors = response.text
      return this.unpaidBillDataById
    } else {
      this.unpaidBillDataById = []
      this.unpaidBillDataById.data = response
      this.unpaidBillDataById.errors = ''
      return this.unpaidBillDataById
    }
  }
  async getReceivePaymentCustomerData(custId) {
    const response = await get('sales', `get-receive-payment-customer-data/${custId}`, 'GET')
    this.unpaidBillDataById = []
    this.unpaidBillDataById.data = response
    return this.unpaidBillDataById
  }

  async calculateTotalAmount(list) {
    var totalAmount = 0
    // get total amount using input list items amount
    list.map((list) => {
      if (!list.amount) {
        list.amount = 0
      }
      totalAmount = Number(totalAmount) + Number(list.amount)
    })
    return totalAmount
  }
  async submitSelectedTags(selectedTags, selectedBill_bill_tag) {
    if (selectedTags.length == 0 && selectedBill_bill_tag) {
      var tagArray = []
      selectedBill_bill_tag.map((tag) =>
        tagArray.push({ tag_id: tag.tag_id, transaction_type: 'bill' }),
      )
      return tagArray
    } else {
      return selectedTags
    }
  }

  async setUpdatedInputList(inputList) {
    inputList.map((list) => {
      if (!list.id) {
        list.remaining_balance = list.amount
        list.paid_amount = 0
        list.total_paid_amount = 0
        list.credit_amount = 0
      }
      list.update_amount = list.amount
    })
    return inputList
  }
  async getTermAmount(termsList, term_id) {
    var selectedAmount = 0
    termsList.map((list) => {
      if (list.value == term_id) {
        selectedAmount = list.amount
      }
    })
    return selectedAmount
  }
  async getTodayDate() {
    var now = new Date()
    var month = now.getMonth() + 1
    var day = now.getDate()
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    var today = now.getFullYear() + '-' + month + '-' + day
    return today
  }
  async getCalculateDate(addDays) {
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    var date = new Date()
    var calDate = date.addDays(addDays)
    var month = calDate.getMonth() + 1
    var day = calDate.getDate()
    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day
    return calDate.getFullYear() + '-' + month + '-' + day
  }
  async createLedgerTransaction(payment_date, payment_account, totalAmount, memo, inputList) {
    var total_payment = 0
    var ledgerTransaction = {
      chart_of_accounts: [],
      credits: [],
      debits: [],
      memos: [],
      transaction_date: payment_date,
    }
    function bindChartOfAccount(coa, debit, credit, memo) {
      ledgerTransaction.chart_of_accounts.push(coa)
      ledgerTransaction.debits.push(debit)
      ledgerTransaction.credits.push(credit)
      ledgerTransaction.memos.push(memo)
    }
    await Promise.all(
      inputList.map((list) => {
        total_payment = total_payment + Number(payment_account)
        bindChartOfAccount(
          Number(list.category),
          Number(list.amount),
          Number(0.0),
          list.description,
        )
      }),
    ).then(() => {
      bindChartOfAccount(Number(payment_account), Number(0.0), Number(totalAmount), memo)
    })
    return await this.chartOfAccount.storeLedgerTransaction(ledgerTransaction)
  }
  async updateLedgerTransaction(
    trans_id,
    payment_date,
    payment_account,
    totalAmount,
    memo,
    inputList,
  ) {
    var total_payment = 0
    var ledgerTransaction = {
      chart_of_accounts: [],
      credits: [],
      debits: [],
      memos: [],
      transaction_date: payment_date,
    }
    function bindChartOfAccount(coa, debit, credit, memo) {
      ledgerTransaction.chart_of_accounts.push(coa)
      ledgerTransaction.debits.push(debit)
      ledgerTransaction.credits.push(credit)
      ledgerTransaction.memos.push(memo)
    }
    await Promise.all(
      inputList.map((list) => {
        total_payment = total_payment + Number(payment_account)
        bindChartOfAccount(
          Number(list.category),
          Number(list.amount),
          Number(0.0),
          list.description,
        )
      }),
    ).then(() => {
      bindChartOfAccount(Number(payment_account), Number(0.0), Number(totalAmount), memo)
    })
    return await this.chartOfAccount.updateLedgerTransaction(ledgerTransaction, trans_id)
  }
  async deleteLedgerTransaction(ledger_transaction_id) {
    return await this.chartOfAccount.deleteLedgerTransaction(ledger_transaction_id)
  }
}
export default TransactionBill
