import { get, post, destroy } from '../utils/request'
import * as _ from 'lodash'

class ChartOfAccountService {
  module = `chartOfAccount`

  getAllAccountType() {
    return get(this.module, `api/account-type`)
  }

  create(data) {
    return post(this.module, `api/chart-of-account`, data)
  }

  update(data, id) {
    return post(this.module, `api/chart-of-account/${id}`, data, 'PUT')
  }

  list(page = 1, page_length = 50, sort = 'name', direction = 'asc', type = '') {
    return get(
      this.module,
      `api/chart-of-account?page=${page}&page_length=${page_length}&sort=${sort}&direction=${direction}&type=${type}`,
    )
  }

  show(id) {
    return get(this.module, `api/chart-of-account/${id}`)
  }

  makeInactive(id, data) {
    return post(this.module, `api/chart-of-account/status/${id}`, data)
  }

  async getAllAccount(param) {
    if (param) {
      var type = param
    }
    const response = await get(
      this.module,
      `api/all-chart-of-account?type=${type}&type_filter=parent`,
    )
    return response.data
  }

  async getAllCOA() {
    const response = await get(this.module, `api/all-chart-of-account?type_filter=parent`)
    return response.data
  }
  async getBankAccounts() {
    const response = await get(
      this.module,
      `api/chart-of-account?page_length=10000&page=1&type=Bank&type_filter=parent`,
    )
    return response.data
  }

  async getPaymentMethods() {
    const response = await get(this.module, `api/payment-methods`)
    return response.data
  }

  async storeLedgerTransaction(data) {
    const response = await post(this.module, `api/general-ledger-transaction`, data)
    return response
  }

  transformList(chartOfAccounts) {
    if (!Array.isArray(chartOfAccounts)) return []
    return chartOfAccounts.map((element) => {
      //let allowToAddStartingBalance = element.account_type.allow_to_add_starting_balance
      let allowToAddStartingBalance = true
      return {
        id: element.id,
        name: element.name,
        account_type: element.account_type.name,
        detail_type: element.detail_type.name,
        ending_balance: element.ending_balance,
        is_active: element.is_active,
        allow_to_add_starting_balance: allowToAddStartingBalance,
        //having_balance: !element.closing_balance  ? false : true,
        parent_account: Object.keys(element.sub_account).length ? element.sub_account.id : 0,
        having_balance: true,
        closing_balance: allowToAddStartingBalance
          ? `${(Math.round(element.closing_balance * 100) / 100).toFixed(2)} ${element.currency}`
          : ``,
        bank_balance: allowToAddStartingBalance ? `0.00 ${element.currency}` : ``,
      }
    })
  }
  async updateLedgerTransaction(data, id) {
    const response = await post(this.module, `api/general-ledger-transaction/${id}`, data, 'PUT')
    return response
  }
  async deleteLedgerTransaction(ledger_id) {
    const response = await destroy(this.module, `api/general-ledger-transaction/${ledger_id}`)
    return response
  }

  transformListCustom(chartOfAccounts) {
    if (!Array.isArray(chartOfAccounts)) return []
    let chartOfAccountFirstParentAndThenChild = []
    chartOfAccounts.forEach((element) => {
      if (!element.parent_account) {
        chartOfAccountFirstParentAndThenChild.push(element)
        let childAccounts = _.reject(chartOfAccounts, function (o) {
          return element.id !== o.parent_account
        })
        chartOfAccountFirstParentAndThenChild =
          chartOfAccountFirstParentAndThenChild.concat(childAccounts)
      }
    })

    return chartOfAccountFirstParentAndThenChild
  }

  transformListForDD(chartOfAccounts) {
    let lastOptgroup = ''
    let optgroupItems = []
    let lastKey = chartOfAccounts.length - 1
    return _.reduce(
      chartOfAccounts,
      function (result, value, key) {
        // eslint-disable-next-line eqeqeq
        if (lastOptgroup != value.account_type && !value.parent_account) {
          if (optgroupItems.length) {
            result.push({ optgroup: lastOptgroup, options: optgroupItems })
          }
          lastOptgroup = value.account_type
          optgroupItems = []
        }
        optgroupItems.push({
          value: value.id,
          label: value.parent_account ? `|--${value.name}` : value.name,
        })

        // eslint-disable-next-line eqeqeq
        if (lastKey == key) {
          result.push({ optgroup: lastOptgroup, options: optgroupItems })
        }
        return result
      },
      [],
    )
  }

  isAccountReceivable(account) {
    return account.account_type.id === 1 && account.detail_type.id === 16
  }

  isAccountPayable(account) {
    return account.account_type.id === 6 && account.detail_type.id === 64
  }
}

export default ChartOfAccountService
