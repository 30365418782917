import { get } from '../utils/request'
import { inject } from 'dependency-injection-es6'
import ChartOfAccountService from './ChartOfAccountService'

class TransactionCredit {
  @inject(ChartOfAccountService) chartOfAccount: ChartOfAccountService
  creditDataById = null

  async getCredit() {
    const pageStart = 0
    const pageLength = 1000
    const response = await get(
      'expense',
      `credits?page=${pageStart}&page_length=${pageLength}`,
      'GET',
    )
    console.log(response[0].data)
    return response[0].data
  }

  async getCreditById(id) {
    const response = await get('expense', `credits/${id}`, 'GET')
    this.creditDataById = response.credits[0]
    this.creditDataById.credit_list = response.credit_list
    this.creditDataById.credit_tag = response.credit_tag
    return this.creditDataById
  }
  async calculateTotalAmount(list) {
    var totalAmount = 0
    // get total amount using input list items amount
    list.map((list) => {
      if (!list.amount) {
        list.amount = 0
      }
      totalAmount = Number(totalAmount) + Number(list.amount)
    })
    return totalAmount
  }

  async submitSelectedTags(selectedTags, selectedCredit_Credit_tag) {
    if (selectedTags.length == 0 && selectedCredit_Credit_tag) {
      var tagArray = []
      selectedCredit_Credit_tag.map((tag) =>
        tagArray.push({ tag_id: tag.tag_id, transaction_type: 'vendor_credit' }),
      )
      return tagArray
    } else {
      return selectedTags
    }
  }

  async createLedgerTransaction(payment_date, payment_account, totalAmount, memo, inputList) {
    var ledgerTransaction = {
      chart_of_accounts: [],
      credits: [],
      debits: [],
      memos: [],
      transaction_date: payment_date,
    }
    function bindChartOfAccount(coa, credit, debit, memo) {
      ledgerTransaction.chart_of_accounts.push(coa)
      ledgerTransaction.debits.push(debit)
      ledgerTransaction.credits.push(credit)
      ledgerTransaction.memos.push(memo)
    }
    inputList.map((list) => {
      bindChartOfAccount(Number(payment_account), Number(0.0), Number(list.amount), memo)
      bindChartOfAccount(Number(list.category), Number(list.amount), Number(0.0), list.description)
    })
    return await this.chartOfAccount.storeLedgerTransaction(ledgerTransaction)
  }
  async updateLedgerTransaction(
    trans_id,
    payment_date,
    payment_account,
    totalAmount,
    memo,
    inputList,
  ) {
    var ledgerTransaction = {
      chart_of_accounts: [],
      credits: [],
      debits: [],
      memos: [],
      transaction_date: payment_date,
    }
    function bindChartOfAccount(coa, credit, debit, memo) {
      ledgerTransaction.chart_of_accounts.push(coa)
      ledgerTransaction.debits.push(debit)
      ledgerTransaction.credits.push(credit)
      ledgerTransaction.memos.push(memo)
    }
    inputList.map((list) => {
      bindChartOfAccount(Number(payment_account), Number(0.0), Number(list.amount), memo)
      bindChartOfAccount(Number(list.category), Number(list.amount), Number(0.0), list.description)
    })
    return await this.chartOfAccount.updateLedgerTransaction(ledgerTransaction, trans_id)
  }
  async deleteLedgerTransaction(ledger_transaction_id) {
    return await this.chartOfAccount.deleteLedgerTransaction(ledger_transaction_id)
  }
}
export default TransactionCredit
