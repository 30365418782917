import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import { GET_PROFILE } from '../../actions/user-profile/userProfileSettings'

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  settings: [],
  advSettings: [],
  userSettings: [],
})

const setUserSettings = (data) => (state) => {
  console.log('data', data)
  state.setIn(['userSettings'], data)
}
const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)
const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
function profileSettings(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_PROFILE.DO:
      return mutate([resetStatusSetLoading()])
    case GET_PROFILE.SUCCESS:
      return mutate([resetStatus(), setUserSettings(action.payload)])
    case GET_PROFILE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setUserSettings([])])
    default:
      return state
  }
}

export default profileSettings
