import { createAction, createTypes } from '../../../utils/actions'
import { get } from '../../../utils/request'
import { showErrorToastAction } from '../global'

export const GET_ACCOUNTS = createTypes('ZOOM/SALES/GET_ACCOUNTS')
export const GET_ACCOUNT_TYPES = createTypes('ZOOM/SALES/GET_ACCOUNT_TYPES')
export const GET_GENERAL_TRANSACTION = createTypes('ZOOM/SALES/GET_GENERAL_TRANSACTION')
export const GET_GCLOSING_BALANCE = createTypes('ZOOM/SALES/GET_GCLOSING_BALANCE')
export const SET_CURRENT_PAGE = 'ZOOM/SALES/SET_ACCOUNT_CURRENT_PAGE'
export const SET_SIZE_PER_PAGE = 'ZOOM/SALES/SET_ACCOUNT_SIZE_PER_PAGE'
export const SET_TOTAL_SIZE = 'ZOOM/SALES/SET_ACCOUNT_TOTAL_SIZE'
export const setSizePerPage = (params) => createAction(SET_SIZE_PER_PAGE, { payload: params })
export const setCurrentPage = (params) => createAction(SET_CURRENT_PAGE, { payload: params })
export const setTotalSize = (params) => createAction(SET_TOTAL_SIZE, { payload: params })
const getAccountsAction = {
  do: (params) => createAction(GET_ACCOUNTS.DO, params),
  success: (accounts) => createAction(GET_ACCOUNTS.SUCCESS, { payload: accounts }),
  failed: (errors) => createAction(GET_ACCOUNTS.FAILED, { payload: errors }),
}
const getAccountTypesAction = {
  do: (params) => createAction(GET_ACCOUNT_TYPES.DO, params),
  success: (accountTypes) => createAction(GET_ACCOUNT_TYPES.SUCCESS, { payload: accountTypes }),
  failed: (errors) => createAction(GET_ACCOUNT_TYPES.FAILED, { payload: errors }),
}
const getGeneralTransactionAction = {
  do: (params) => createAction(GET_GENERAL_TRANSACTION.DO, params),
  success: (generalTransaction) =>
    createAction(GET_GENERAL_TRANSACTION.SUCCESS, { payload: generalTransaction }),
  failed: (errors) => createAction(GET_GENERAL_TRANSACTION.FAILED, { payload: errors }),
}

const getClosingBalanceAction = {
  do: (params) => createAction(GET_GCLOSING_BALANCE.DO, params),
  success: (closingBalance) =>
    createAction(GET_GCLOSING_BALANCE.SUCCESS, { payload: closingBalance }),
  failed: (errors) => createAction(GET_GCLOSING_BALANCE.FAILED, { payload: errors }),
}
const getAccounts =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getAccountsAction.do())
      const resp = await get(
        'chartOfAccount',
        `api/chart-of-account?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getAccountsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      dispatch(getAccountsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getGeneralTransactions =
  (pageStart = 0, pageLength = 10, coaId = '') =>
  async (dispatch) => {
    try {
      dispatch(setCurrentPage(pageStart))
      dispatch(setSizePerPage(pageLength))
      dispatch(getGeneralTransactionAction.do())
      dispatch(getGeneralTransactionAction.success([]))
      const resp = await get(
        'chartOfAccount',
        `api/general-ledger-transaction?page=${pageStart}&page_length=${pageLength}&search=chart_of_accounts_id=${coaId}`,
      )
      dispatch(getGeneralTransactionAction.success(resp.data))
      dispatch(getClosingBalanceAction.success(resp.closing_balance))
      dispatch(setTotalSize(resp.total))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      dispatch(getGeneralTransactionAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getAccountTypes =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getAccountTypesAction.do())
      const resp = await get(
        'chartOfAccount',
        `api/account-type?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getAccountTypesAction.success(resp))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting account types.'
      dispatch(getAccountTypesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getAccountDataArray =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      const resp = await get(
        'chartOfAccount',
        `api/chart-of-account?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      return dispatch(showErrorToastAction(error))
    }
  }
const getCreditAccountDataArray =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      const resp = await get(
        'chartOfAccount',
        `api/all-chart-of-account?type=${'Credit Card'}&type_filter=parent&page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      return dispatch(showErrorToastAction(error))
    }
  }

const getBankAccountDataArray =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      const type = ['Bank,Credit Card,Other Current Assets']
      const resp = await get(
        'chartOfAccount',
        `api/all-chart-of-account?type=${type}&type_filter=parent&page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      return dispatch(showErrorToastAction(error))
    }
  }

const getBankDataArray =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      const resp = await get(
        'chartOfAccount',
        `api/all-chart-of-account?type=${'Bank'}&type_filter=parent&page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      return dispatch(showErrorToastAction(error))
    }
  }
const getAssetsDataArray =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      const type = ['Bank,Other Current Assets']
      const resp = await get(
        'chartOfAccount',
        `api/all-chart-of-account?type=${type}&type_filter=parent&page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      return dispatch(showErrorToastAction(error))
    }
  }
const getIncomeDataArray =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      const resp = await get(
        'chartOfAccount',
        `api/all-chart-of-account?type=${'Income'}&type_filter=parent&page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      return dispatch(showErrorToastAction(error))
    }
  }
const getExpenseDataArray =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      const resp = await get(
        'chartOfAccount',
        `api/all-chart-of-account?type=${'Cost of goods sold'}&type_filter=parent&page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      return dispatch(showErrorToastAction(error))
    }
  }
const getBankTransferDataArray =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      const type = [
        'Other Current Assets',
        'Bank',
        'Fixed Assets',
        'Other Assets',
        'Credit Card',
        'Other Current Liabilities',
        'Long term Liabilities',
        'Equity',
      ]
      const resp = await get(
        'chartOfAccount',
        `api/all-chart-of-account?type=${type}&type_filter=parent&page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting accounts.'
      return dispatch(showErrorToastAction(error))
    }
  }

export {
  getAccountsAction,
  getGeneralTransactionAction,
  getClosingBalanceAction,
  getAccounts,
  getAccountTypes,
  getGeneralTransactions,
  getAccountDataArray,
  getCreditAccountDataArray,
  getBankAccountDataArray,
  getBankDataArray,
  getAssetsDataArray,
  getIncomeDataArray,
  getExpenseDataArray,
  getBankTransferDataArray,
}
