import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from './store'
import GlobalService from './services/GlobalService'
import TagManager from 'react-gtm-module'

async function init() {
  const tagManagerArgs = {
    gtmId: 'GTM-5C9QP5NQ',
  }
  TagManager.initialize(tagManagerArgs)

  const MOUNT_NODE = document.getElementById('root')
  const initialState = {}
  const store = await configureStore(initialState)
  const render = () => {
    ReactDOM.render(
      <Provider store={store}>
        <GlobalService>
          <App />
        </GlobalService>
      </Provider>,
      MOUNT_NODE,
    )
  }
  render()
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
init()
