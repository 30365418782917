import { get } from '../utils/request'
import apiRoot from '../_data/apiRoot'

class TrialReport {
  async getPDFURL(pdfUrl) {
    var apiRootValue = `${apiRoot['report']}`
    console.log(apiRootValue.split('api/')[0])
    var pdf_url = apiRootValue.split('api/')[0] + pdfUrl.pdfUrl
    return pdf_url
  }
}
export default TrialReport
