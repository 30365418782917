import { createAction, createTypes } from '../../../utils/actions'
import { get, post, put } from '../../../utils/request'
import { showErrorToastAction, showSuccessToastAction } from '../global'

export const GET_HSN_TAX_RATES = createTypes('ZOOM/SALES/GET_HSN_TAX_RATES')
export const CREATE_HSN_TAX_RATES = createTypes('ZOOM/SALES/CREATE_HSN_TAX_RATES')
export const UPDATE_HSN_TAX_RATES = createTypes('ZOOM/SALES/UPDATE_HSN_TAX_RATES')
export const GET_HSN_TAX_RATES_BY_ID = createTypes('ZOOM/SALES/GET_HSN_TAX_RATES_BY_ID')
export const INIT_SELECTED_HSN_TAX_RATES = 'ZOOM/SALES/INIT_SELECTED_HSN_TAX_RATES'
export const SET_CURRENT_PAGE = 'ZOOM/SALES/SET_HSN_TAX_CURRENT_PAGE'
export const SET_SIZE_PER_PAGE = 'ZOOM/SALES/SET_HSN_TAX_SIZE_PER_PAGE'
export const SET_TOTAL_SIZE = 'ZOOM/SALES/SET_HSN_TAX_TOTAL_SIZE'
export const GET_TAX_RATES_LIST = createTypes('ZOOM/SALES/GET_TAX_RATES_LIST')

export const setSizePerPage = (params) => createAction(SET_SIZE_PER_PAGE, { payload: params })
export const setCurrentPage = (params) => createAction(SET_CURRENT_PAGE, { payload: params })
export const setTotalSize = (params) => createAction(SET_TOTAL_SIZE, { payload: params })
const getHsnTaxRatesAction = {
  do: (params) => createAction(GET_HSN_TAX_RATES.DO, params),
  success: (hsnTaxRates) => createAction(GET_HSN_TAX_RATES.SUCCESS, { payload: hsnTaxRates }),
  failed: (errors) => createAction(GET_HSN_TAX_RATES.FAILED, { payload: errors }),
}

const createHsnTaxRatesAction = {
  do: (params) => createAction(CREATE_HSN_TAX_RATES.DO, params),
  success: () => createAction(CREATE_HSN_TAX_RATES.SUCCESS),
  failed: (errors) => createAction(CREATE_HSN_TAX_RATES.FAILED, { errors }),
}
const updateHsnTaxRatesAction = {
  do: (params) => createAction(UPDATE_HSN_TAX_RATES.DO, params),
  success: () => createAction(UPDATE_HSN_TAX_RATES.SUCCESS),
  failed: (errors) => createAction(UPDATE_HSN_TAX_RATES.FAILED, { errors }),
}

const getHsnTaxByIdAction = {
  do: (params) => createAction(GET_HSN_TAX_RATES_BY_ID.DO, params),
  success: (data) => createAction(GET_HSN_TAX_RATES_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_HSN_TAX_RATES_BY_ID.FAILED, { errors }),
}

const getTaxRatesListAction = {
  do: (params) => createAction(GET_TAX_RATES_LIST.DO, params),
  success: (taxRates) => createAction(GET_TAX_RATES_LIST.SUCCESS, { payload: taxRates }),
  failed: (errors) => createAction(GET_TAX_RATES_LIST.FAILED, { payload: errors }),
}
const getHsnTaxRates =
  (pageStart = 0, pageLength = 10, filter = {}) =>
  async (dispatch) => {
    try {
      dispatch(setCurrentPage(pageStart))
      dispatch(setSizePerPage(pageLength))
      dispatch(getHsnTaxRatesAction.do())
      const filterStr = JSON.stringify(filter)

      const resp = await get(
        'sales',
        `hsn-codes?page=${pageStart}&page_length=${pageLength}&search=${filterStr}`,
        'GET',
      )
      dispatch(setTotalSize(resp.total))
      dispatch(getHsnTaxRatesAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting HSN List.'
      dispatch(getHsnTaxRatesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createHsnTaxRates = (params) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(createHsnTaxRatesAction.do())
      params['bundles'] = JSON.stringify(params['bundles'])
      const resp = await post('sales', `products`, params, 'POST', {
        contentType: 'multipart/form-data',
      })
      dispatch(showSuccessToastAction('Successfully created.'))
      return dispatch(createHsnTaxRatesAction.success(resp))
    } catch (e) {
      const error = e?.body ? e.body : 'An error occurred while creating product.'
      dispatch(createHsnTaxRatesAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}
const updateHsnTaxRates = (id, params) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(updateHsnTaxRatesAction.do())
      params['bundles'] = JSON.stringify(params['bundles'])
      const resp = await put('sales', `products/${id}`, params)
      dispatch(showSuccessToastAction('Successfully updated.'))
      return dispatch(updateHsnTaxRatesAction.success(resp))
    } catch (e) {
      console.log(e, 'eeeeeerrr')
      const error = e?.body ? e.body : 'An error occurred while updating product.'
      dispatch(updateHsnTaxRatesAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}

const initSelectedHsnTax =
  (hsnTaxRates = null) =>
  (dispatch) => {
    dispatch(createAction(INIT_SELECTED_HSN_TAX_RATES, { payload: hsnTaxRates }))
  }

const getTaxRatesList =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getTaxRatesListAction.do())
      const resp = await get(
        'sales',
        `tax-rates?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getTaxRatesListAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting tax rates.'
      dispatch(getTaxRatesListAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
export { getHsnTaxRates, initSelectedHsnTax, getTaxRatesList }
