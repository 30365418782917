import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { get, post, upload } from '../../../utils/request'

export const UPDATE_CURRENCY_ACTION = createTypes('ZOOM/SETTINGS/CURRENCY')
export const GET_CURRENCY = createTypes('ZOOM/SETTINGS/GET_CURRENCY')
export const UPDATE_DATE_FORMATE_ACTION = createTypes('ZOOM/SETTINGS/DATE_FORMATE')
export const GET_DATE_FORMATE = createTypes('ZOOM/SETTINGS/GET_DATE_FORMATE')
export const GET_NAV = createTypes('ZOOM/SETTINGS/NAV')
export const UPDATE_COMPANYDETAIL_ACTION = createTypes('ZOOM/SETTINGS/COMPANYDETAIL')
export const GET_COMPANY = createTypes('ZOOM/SETTINGS/GET_COMPANY')
export const UPDATE_COMPANYINFO_ACTION = createTypes('ZOOM/SETTINGS/COMPANYINFO')
export const UPDATE_COMPANYTYPE_ACTION = createTypes('ZOOM/SETTINGS/COMPANYTYPE')
export const UPDATE_COMPANYADDRESS_ACTION = createTypes('ZOOM/SETTINGS/COMPANYADDRESS')

const setNavDataAction = {
  do: () => createAction(GET_NAV.DO),
  success: (navData) => createAction(GET_NAV.SUCCESS, { payload: navData }),
  failed: (errors) => createAction(GET_NAV.FAILED, { errors }),
}

const updateCurrencyAcction = {
  do: () => createAction(UPDATE_CURRENCY_ACTION.DO),
  success: () => createAction(UPDATE_CURRENCY_ACTION.SUCCESS),
  failed: (errors) => createAction(UPDATE_CURRENCY_ACTION.FAILED, { errors }),
}

const getCurrencyAction = {
  do: (params) => createAction(GET_CURRENCY.DO, params),
  success: (currency) => createAction(GET_CURRENCY.SUCCESS, { payload: currency }),
  failed: (errors) => createAction(GET_CURRENCY.FAILED, { payload: errors }),
}

const updateCompanyDetailsAction = {
  do: () => createAction(UPDATE_COMPANYDETAIL_ACTION.DO),
  success: () => createAction(UPDATE_COMPANYDETAIL_ACTION.SUCCESS),
  failed: (errors) => createAction(UPDATE_COMPANYDETAIL_ACTION.FAILED, { errors }),
}

const getCompanyAction = {
  do: (params) => createAction(GET_COMPANY.DO, params),
  success: (detail) => createAction(GET_COMPANY.SUCCESS, { payload: detail }),
  failed: (errors) => createAction(GET_COMPANY.FAILED, { payload: errors }),
}

const updateCompanyInfoAction = {
  do: () => createAction(UPDATE_COMPANYINFO_ACTION.DO),
  success: () => createAction(UPDATE_COMPANYINFO_ACTION.SUCCESS),
  failed: (errors) => createAction(UPDATE_COMPANYINFO_ACTION.FAILED, { errors }),
}

const updateCompanyTypeAction = {
  do: () => createAction(UPDATE_COMPANYTYPE_ACTION.DO),
  success: () => createAction(UPDATE_COMPANYTYPE_ACTION.SUCCESS),
  failed: (errors) => createAction(UPDATE_COMPANYTYPE_ACTION.FAILED, { errors }),
}

const updateAddressAction = {
  do: () => createAction(UPDATE_COMPANYADDRESS_ACTION.DO),
  success: () => createAction(UPDATE_COMPANYADDRESS_ACTION.SUCCESS),
  failed: (errors) => createAction(UPDATE_COMPANYADDRESS_ACTION.FAILED, { errors }),
}

const updateDateFormateAcction = {
  do: () => createAction(UPDATE_DATE_FORMATE_ACTION.DO),
  success: () => createAction(UPDATE_DATE_FORMATE_ACTION.SUCCESS),
  failed: (errors) => createAction(UPDATE_DATE_FORMATE_ACTION.FAILED, { errors }),
}

const getDateFormateAction = {
  do: (params) => createAction(GET_DATE_FORMATE.DO, params),
  success: (currency) => createAction(GET_DATE_FORMATE.SUCCESS, { payload: currency }),
  failed: (errors) => createAction(GET_DATE_FORMATE.FAILED, { payload: errors }),
}

const setNavData = () => async (dispatch) => {
  try {
    dispatch(setNavDataAction.do())
    const resp = await get(`user`, 'api/my-permissions')
    dispatch(setNavDataAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while loading fast link params.'
    dispatch(setNavDataAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const updateCurrency = (postData) => async (dispatch) => {
  try {
    dispatch(updateCurrencyAcction.do())
    const resp = await post(`user`, 'api/account-settings-advance/currency', postData)
    dispatch(updateCurrencyAcction.success(resp?.data))
    showSuccessToastAction('CUrrency has been updated')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while loading fast link params.'
    dispatch(updateCurrencyAcction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const updateCompanyDetails = (postData) => async (dispatch) => {
  try {
    dispatch(updateCompanyDetailsAction.do())

    const resp = await upload('user', `api/account-settings-company/details`, postData)
    dispatch(updateCompanyDetailsAction.success(resp?.data))
    showSuccessToastAction('Company Details has been updated')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while loading fast link params.'
    dispatch(updateCompanyDetailsAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const updateCompanyInfo = (postData) => async (dispatch) => {
  try {
    dispatch(updateCompanyInfoAction.do())
    const resp = await post(`user`, 'api/account-settings-company/info', postData)
    dispatch(updateCompanyInfoAction.success(resp?.data))
    showSuccessToastAction('Company info has been updated')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while loading fast link params.'
    dispatch(updateCompanyInfoAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const getSettingAdvanceData = () => async (dispatch) => {
  try {
    dispatch(getCurrencyAction.do())
    const resp = await get('user', 'api/account-settings-advance', 'GET')
    dispatch(getCurrencyAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoices.'
    dispatch(getCurrencyAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const getSettingCompanyData = () => async (dispatch) => {
  try {
    dispatch(getCompanyAction.do())
    const resp = await get('user', 'api/account-settings-company', 'GET')
    dispatch(getCompanyAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoices.'
    dispatch(getCompanyAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const updateDateFormate = (postData) => async (dispatch) => {
  try {
    dispatch(updateDateFormateAcction.do())
    const resp = await post(`user`, 'api/account-settings-advance/date-formate', postData)
    dispatch(updateDateFormateAcction.success(resp?.data))
    showSuccessToastAction('CUrrency has been updated')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while loading fast link params.'
    dispatch(updateDateFormateAcction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const updateCompanyType = (postData) => async (dispatch) => {
  try {
    dispatch(updateCompanyTypeAction.do())
    const resp = await post('user', 'api/account-settings-company/type', postData)
    dispatch(updateCompanyTypeAction.success(resp?.data))
    showErrorToastAction('Company type has been updated')
  } catch (e) {
    const error = e.body ? e.body : 'An error occured while loading fast link params.'
    return dispatch(showErrorToastAction(error))
  }
}

const updateCompanyAddress = (postData) => async (dispatch) => {
  try {
    dispatch(updateAddressAction.do())
    const resp = await post('user', 'api/account-settings-company/address', postData)
    dispatch(updateAddressAction.success(resp?.data))
    showErrorToastAction('Company type has been updated')
  } catch (e) {
    const error = e.body ? e.body : 'An error occured while loading fast link params.'
    return dispatch(showErrorToastAction(error))
  }
}

const getTaxPayerDetails = (postData) => async (dispatch) => {
  try {
    // dispatch(getCurrencyAction.do())
    const resp = await get('user', `api/tax-payer-details/${postData.gstin}`, 'GET')
    // dispatch(getCurrencyAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoices.'
    dispatch(getCurrencyAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

export {
  getCompanyAction,
  updateCurrencyAcction,
  getDateFormateAction,
  updateDateFormateAcction,
  setNavDataAction,
  getSettingAdvanceData,
  updateCurrency,
  updateDateFormate,
  setNavData,
  updateCompanyDetails,
  updateCompanyInfo,
  getSettingCompanyData,
  updateCompanyType,
  updateCompanyAddress,
  getTaxPayerDetails,
}
