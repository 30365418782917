import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { get, post, put } from '../../../utils/request'

export const GET_PROFILE = createTypes('ZOOM/SETTINGS/GET_PROFILE')
export const UPDATE_PASSWORD = createTypes('ZOOM/SETTINGS/UPDATE_PASSWORD')
export const UPDATE_PROFILE = createTypes('ZOOM/SETTINGS/UPDATE_PROFILE')

const getProfileAction = {
  do: (params) => createAction(GET_PROFILE.DO, params),
  success: (detail) => createAction(GET_PROFILE.SUCCESS, { payload: detail }),
  failed: (errors) => createAction(GET_PROFILE.FAILED, { payload: errors }),
}

const updatePasswordAction = {
  do: (params) => createAction(UPDATE_PASSWORD.DO, params),
  success: (detail) => createAction(UPDATE_PASSWORD.SUCCESS, { payload: detail }),
  failed: (errors) => createAction(UPDATE_PASSWORD.FAILED, { payload: errors }),
}
const updateProfile = {
  do: (params) => createAction(UPDATE_PROFILE.DO, params),
  success: (detail) => createAction(UPDATE_PROFILE.SUCCESS, { payload: detail }),
  failed: (errors) => createAction(UPDATE_PROFILE.FAILED, { payload: errors }),
}

const getUserProfileData = () => async (dispatch) => {
  try {
    dispatch(getProfileAction.do())
    const resp = await get('user', 'api/account-user-profile', 'GET')
    console.log('resp', resp)
    dispatch(getProfileAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting User Data.'
    dispatch(getProfileAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const updateUserDetails = (postData) => async (dispatch) => {
  try {
    dispatch(updateProfile.do())

    const resp = await put('user', `api/account-user-profile`, postData)
    dispatch(updateProfile.success(resp?.data))
    showSuccessToastAction('User Details has been updated')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while loading fast link params.'
    dispatch(updateProfile.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const updatePassword = (postData) => async (dispatch) => {
  try {
    dispatch(updatePasswordAction.do())
    const resp = await post('user', 'api/update-password', postData)
    dispatch(updatePasswordAction.success(resp?.data))
    dispatch(showSuccessToastAction(resp.success))
  } catch (e) {
    const error = e.body ? e.body : 'An error occured while loading fast link params.'
    return dispatch(showErrorToastAction(error))
  }
}

export { getUserProfileData, updatePassword, updateUserDetails }
